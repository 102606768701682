// @flow
import * as React from 'react';
import { point } from 'leaflet';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-leaflet';
import moment from 'moment';
import type { TCoordinate } from '../../../types';
import { humanizeTimeStamp } from '../../../utils/dates.utils';
import { bargeIcon } from './BargeIcon';
import { OrientedMarkerWithChildren } from './Marker';

type TProps = {
  direction: number,
  lastUpdate: string,
  position: TCoordinate,
  speed: number,
};

export function BargePosition(props: TProps) {
  return (
    <OrientedMarkerWithChildren icon={bargeIcon()} position={props.position} angle={props.direction}>
      <Tooltip permanent offset={point(0, 20)} direction="right">
        <span>
          {props.speed && props.speed > 0 ? (
            <span>
              <strong>
                <FormattedMessage id="atoms.map.speed" defaultMessage="Speed" />:
              </strong>{' '}
              {props.speed} kn
            </span>
          ) : (
            <FormattedMessage id="atoms.map.no_speed" defaultMessage="Not moving" />
          )}
          <br />
          <strong>
            <FormattedMessage id="atoms.map.last_update" defaultMessage="Last update" />:
          </strong>
          <br />
          {humanizeTimeStamp(moment(props.lastUpdate))}
        </span>
      </Tooltip>
    </OrientedMarkerWithChildren>
  );
}
