// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constants/pagination.constants';
import { getBargeOperatorPageAction, removeBargeOperatorAction } from '../../../store/actions';
import { bargeOperatorListSelector } from '../../../store/selectors/barge_operator.selectors';
import { createPath } from '../../../utils/routing.utils';
import { BargeOperatorTableProps } from '../../atoms/Tables/BargeOperatorTableProps';
import { SimpleTable } from '../../atoms/Tables/SimpleTable';
import { ListTemplate } from '../../templates/MasterData/ListTemplate';
import { SITE_PATHS } from '../../../config/api.constants';
import { useNavigate } from 'react-router-dom';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.barge_operator.list.header.title', defaultMessage: 'Barge Operators' },
  button: { id: 'pages.barge_operator.list.header.button', defaultMessage: 'Add new barge operator' },
});

function BargeOperatorList(props: TProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(bargeOperatorListSelector);

  const loadPage = (page: number, pageSize: number) => {
    dispatch(getBargeOperatorPageAction({ filter: {}, paging: { size: pageSize, number: page } }));
  };
  const goToDetail = (record) => navigate(createPath(SITE_PATHS.BARGE_OPERATOR_DETAIL, { id: record.id }));
  const deleteRow = (record) => dispatch(removeBargeOperatorAction(record));

  useEffect(() => {
    loadPage(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
  }, [dispatch]);

  return (
    <ListTemplate
      buttonText={props.intl.formatMessage(EXTRA_TRANSLATIONS.button)}
      createRoute={SITE_PATHS.BARGE_OPERATOR_CREATE}
      title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)}
      menuBlock="barge-operators"
      menuItem="barge-operators-overview"
    >
      <SimpleTable onRowClick={goToDetail} onPageChange={loadPage} columns={BargeOperatorTableProps(deleteRow)}
                   data={data} rowKey="id" className="barge-table-list" />
    </ListTemplate>
  );
}

export const BargeOperatorListPage = injectIntl(BargeOperatorList);
