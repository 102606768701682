/* eslint-disable no-underscore-dangle,no-param-reassign,func-names */
// @flow
import React,  {useRef, useEffect} from 'react';
import * as L from 'leaflet';
import type { LatLng, MapLayerProps } from 'react-leaflet/lib/types';
import { Marker, useMap } from 'react-leaflet';

const OrientedMarker = L.Marker.extend({
  orientationMouseDown: false,
  savedDragging: false,
  savedMouseUp: false,
  initIconStyle: false,
  orientationLine: false,
  orientationCircle: false,
  orientationActivated: false,
  name: '',

  options: {
    angle: 0,
    orientationLineColor: 'red',
    orientationLineWeight: 3,
    orientationLineOpacity: 0.8,
  },

  initialize(coords, options) {
    L.setOptions(this, options);
    this._latlng = coords;
  },

  /**
   * Set the angle.
   * @param {number} angle - some degree to set the angle
   * @returns {void}
   */
  setAngle(angle) {
    this.options.angle = angle;
    this._updateImg();
  },

  getAngle() {
    return this.options.angle;
  },
  getName() {
    return this.name;
  },
  getIcon() {
    return this.options.icon;
  },
  /**
   * Add degree to the angle.
   * @param {number} angle - some degree to add to the angle
   * @returns {number} The new angle
   */
  rotate(angle) {
    this.options.angle += angle;
    this._updateImg();
    return this.options.angle;
  },

  _setPos(pos) {
    L.Marker.prototype._setPos.call(this, pos);

    this.initIconStyle = `${this._icon.style[L.DomUtil.TRANSFORM]}`;
    this._updateImg();
  },

  setPositionByCoordinates(latLng) {
    this._setPos(this._map.latLngToLayerPoint(latLng));
  },

  _updateImg() {
    this._orienteIcon(this._icon);
  },

  _orienteIcon(icon) {
    icon.style[L.DomUtil.TRANSFORM] = `${this.initIconStyle} rotate(${this.options.angle}deg)`;
    icon.style['transform-origin'] = 'center center';
  },

  onRemove(map) {
    this.deactivateRotation();
    L.Marker.prototype.onRemove.call(this, map);

    return this;
  },

  update() {
    L.Marker.prototype.update.call(this);

    if (this.orientationLine) {
      this.activateOrientation();
    }

    return this;
  },

  activateOrientation() {
    const beginOrientation = () => {
      this.savedDragging = this._map.dragging;
      // $FlowFixMe
      this.savedMouseUp = document.onmouseup;
      this._map.dragging.disable();
      this.orientationMouseDown = true;
    };

    const moveOrientation = (e) => {
      if (this.orientationMouseDown) {
        const pointB = new L.LatLng(e.latlng.lat, e.latlng.lng);

        this.orientationLine.setLatLngs([this._latlng, pointB]);
        this.orientationCircle.setLatLng(pointB);

        this._setAngle();
      }
    };

    const mobileMoveOrientation = (e) => {
      if (this.orientationMouseDown) {
        const touches = e.changedTouches;
        const lastTouch = touches[touches.length - 1];

        const newLatLng = this._map.layerPointToLatLng(this._map.mouseEventToLayerPoint({ clientX: lastTouch.pageX, clientY: lastTouch.pageY }));

        moveOrientation({ latlng: newLatLng });
      }
    };

    const stopOrientation = () => {
      if (this.orientationMouseDown) {
        this.orientationMouseDown = false;
        this._map.dragging.enable();
        this._setAngle();
        this.fire('orientation:stopped');
      }
    };

    this._setOrientationDirectionLine();
    this.orientationMouseDown = false;
    this.orientationLine.addTo(this._map);
    this.orientationCircle.addTo(this._map);
    this.orientationLine.on('mousedown', beginOrientation);
    this.orientationCircle.on('mousedown', beginOrientation);
    this._map.on('mousemove', moveOrientation);

    this.orientationLine.on('mouseup', stopOrientation);
    this.orientationCircle.on('mouseup', stopOrientation);
    // document.onmouseup = stopOrientation;

    // Mobile controls
    if (this.orientationLine._container) {
      this.orientationLine._container.ontouchstart = beginOrientation;
      this.orientationCircle._container.ontouchstart = beginOrientation;
      this.orientationCircle._container.ontouchmove = mobileMoveOrientation;
      this.orientationCircle._container.ontouchmove = mobileMoveOrientation;
      this.orientationCircle._container.ontouchend = stopOrientation;
      this.orientationCircle._container.ontouchend = stopOrientation;
    }

    this.orientationActivated = true;

    return this;
  },

  click() {
    if (this.orientationActivated) {
      this.deactivateRotation();
    } else {
      this.activateOrientation();
    }
  },

  deactivateRotation() {
    if (this.orientationLine && this.orientationCircle) {
      this._map.removeLayer(this.orientationLine);
      this._map.removeLayer(this.orientationCircle);

      this.orientationMouseDown = false;
      this.orientationLine.off('mousedown');
      this.orientationCircle.off('mousedown');
      this._map.off('mousemove');

      this.orientationLine.off('mouseup');
      this.orientationCircle.off('mouseup');

      // Mobile controls
      if (this.orientationLine._container) {
        this.orientationLine._container.ontouchstart = undefined;
        this.orientationCircle._container.ontouchstart = undefined;
        this.orientationCircle._container.ontouchmove = undefined;
        this.orientationCircle._container.ontouchmove = undefined;
        this.orientationCircle._container.ontouchend = undefined;
        this.orientationCircle._container.ontouchend = undefined;
      }

      this.orientationLine = null;
      this.orientationCircle = null;

      this.orientationActivated = false;
    }

    return this;
  },

  _setOrientationDirectionLine() {
    if (this.orientationLine) {
      this._map.removeLayer(this.orientationLine);
      this._map.removeLayer(this.orientationCircle);
    }

    const anglePI = this.options.angle * Math.PI;
    const sinCalc = Math.sin(anglePI / 180);
    const cosCalc = Math.cos(anglePI / 180);

    const angleSinus = sinCalc * 100;
    const angleCos = cosCalc * -100;

    const transformation = new L.Transformation(1, angleSinus, 1, angleCos);

    const pointB = this._map.layerPointToLatLng(transformation.transform(this._map.latLngToLayerPoint(this._latlng)));

    const pointList = [this._latlng, pointB];

    this.orientationLine = new L.Polyline(pointList, {
      color: this.options.orientationLineColor,
      weight: this.options.orientationLineWeight,
      opacity: this.options.orientationLineOpacity,
      smoothFactor: 1,
    });

    this.orientationCircle = new L.CircleMarker(pointB, {
      radius: this.options.orientationLineWeight * 2,
      color: this.options.orientationLineColor,
      fillColor: this.options.orientationLineColor,
      fillOpacity: this.options.orientationLineOpacity,
    });
  },

  _setAngle() {
    const A = this.orientationLine._parts[0][0];
    const B = this.orientationLine._parts[0][1];

    const result = Math.atan2(0, 1) - Math.atan2(B.x - A.x, B.y - A.y);
    const result2 = result * 180;
    const resultDividedByPI = result2 / Math.PI;

    this.options.angle = resultDividedByPI + 180;

    this._updateImg();
  },
});

OrientedMarker.addInitHook(function () {
  this.name = this.options.name || '';
  this._icon = this.options.icon;
});

type TProps = {
  angle?: number,
  draggable?: boolean,
  icon?: L.Icon,
  opacity?: number,
  position: LatLng,
  zIndexOffset?: number,
} & MapLayerProps;

export function OrientedMarkerWithChildren({
                           position,
                           icon,
                           angle,
                           draggable,
                           zIndexOffset,
                           opacity,
                           children,
                         }: TProps) {
  const map = useMap();
  const markerRef = useRef(null);

  useEffect(() => {
    // Initialize the custom marker
    const marker = new OrientedMarker(position, {
      icon,
      angle,
      draggable,
      zIndexOffset,
      opacity,
    });

    marker.addTo(map);
    markerRef.current = marker;

    return () => {
      // Clean up marker on unmount
      marker.remove();
    };
  }, [map, position, icon, angle, draggable, zIndexOffset, opacity]);

  useEffect(() => {

    if (markerRef.current) {
      markerRef.current.setLatLng(position);
      markerRef.current.setIcon(icon);
      markerRef.current.setZIndexOffset(zIndexOffset);
      markerRef.current.setOpacity(opacity);
      markerRef.current.setAngle(angle);
    }
  }, [position, icon, angle, draggable, zIndexOffset, opacity]);

  return children ? <Marker ref={markerRef} position={position} opacity={0}>{children}</Marker> : null;
}

