// @flow
import { Col, Row, Tag } from 'antd';
import qs from 'qs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { API_PATHS, SITE_PATHS } from '../../../config/api.constants';
import { REMARK_TYPES } from '../../../constants/contact.constants';
import { SPACING } from '../../../constants/style.constants';
import {
  archiveInternalErrorsAction,
  getDetailsAction,
  getVisitContainerPageAction,
  priorityVisitAction,
  processVisitAction,
  unarchiveInternalErrorsAction,
  updateBTSAction,
} from '../../../store/actions';
import { createVisitRemarkAction } from '../../../store/actions/visit_remark.actions';
import { updateVisitDetail } from '../../../store/handlers/visits.handler';
import type { TContainerVisit, TError, TPageWithFilter, TVisit, TVisitFilter } from '../../../types';
import type { TVisitRemark } from '../../../types/remark.types';
import { humanizeTimeStamp } from '../../../utils/dates.utils';
import { formatPasteableURL, pageToPageRequest } from '../../../utils/format.utils';
import { Refresh } from '../../atoms/Refresh';
import {
  renderETA,
  renderOPT,
  renderQuayPlanned,
  renderQuayRequested,
  renderRTA,
  renderStatus,
} from '../../atoms/renderers';
import { Socket } from '../../atoms/Socket';
import { ContainerCollapse } from '../../molecules/collapses/Containers';
import { EmptiesYardCollapse } from '../../molecules/collapses/EmptiesYardCollapse';
import { InternalErrorsCollapse } from '../../molecules/collapses/InternalErrors/InternalErrors';
import { RemarkCollapse } from '../../molecules/collapses/Remarks';
import { TalList } from '../containers/TalList';
import { ContactInfoModal } from '../../molecules/modals/ContactInfoModal';
import { ProcessToggle } from './ProcessToggle';
import { PriorityToggle } from './PriorityToggle';
import { ROLES } from '../../../constants/roles.constants';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { Elearning } from '../../atoms/elearning';
import { createPath } from '../../../utils/routing.utils';
import { ExternalErrorsCollapse } from '../../molecules/collapses/ExternalErrors/ExternalErrors';

type TProps = {
  containerPage: TPageWithFilter<TContainerVisit>,
  createVisitRemarkAction: typeof createVisitRemarkAction,
  externalErrors: TError[],
  getVisitContainerPageAction: typeof getVisitContainerPageAction,
  getDetailsAction: typeof getDetailsAction,
  internalErrors: TError[],
  loadingContainerPage: boolean,
  loadingExternalErrors: boolean,
  loadingInternalErrors: boolean,
  loadingLPLRemarks: boolean,
  loadingVisitRemarks: boolean,
  LPLRemarks: TVisitRemark[],
  page: ?TPageWithFilter<TVisitFilter>,
  priorityVisitAction: typeof priorityVisitAction,
  processVisitAction: typeof processVisitAction,
  archiveInternalErrorsAction: typeof archiveInternalErrorsAction,
  unarchiveInternalErrorsAction: typeof unarchiveInternalErrorsAction,
  updateBTSAction: typeof updateBTSAction,
  visit: TVisit,
  visitRemarks: TVisitRemark[],
};

export function VisitDetailComponent(props: TProps) {
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const parameters = qs.parse(searchParams, { ignoreQueryPrefix: true });

    if (parameters.empties_yard) {
      setTimeout(() => {
        const section = document.getElementById('empties-yard-section');

        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 300);
    }
  }, []);

  const getSendErrorsOrNull = () => {
    if (props.visit.bargeOperator && props.visit.bargeOperator.sendErrors) {
      return createPath(API_PATHS.V1.VISIT_SEND_ERRORS, { id: props.visit.id });
    }
    return null;
  };

  const { visit, loadingInternalErrors, internalErrors } = props;
  const parameters = qs.parse(searchParams, { ignoreQueryPrefix: true });

  const formatTal = (
    id: number,
    containerSize: number,
    containerSizeLabel: string,
    numberOfContainersEmpty: number,
    numberOfContainersEmptyError: boolean,
    numberOfContainersFull: number,
    numberOfContainersFullError: boolean,
    tosNumberOfContainersEmpty: number,
    tosNumberOfContainersFull: number,
    direction: string,
    thresholdMismatchBtsTos: number,
  ) => ({
    id,
    containerSize,
    containerSizeLabel,
    numberOfContainersEmpty,
    numberOfContainersEmptyError,
    numberOfContainersFull,
    numberOfContainersFullError,
    tosNumberOfContainersEmpty,
    tosNumberOfContainersFull,
    direction,
    thresholdMismatchBtsTos,
  });

  const formatTalList = (direction: 'In' | 'Out') => [
    formatTal(
      1,
      20,
      '20ft',
      visit[`btsNumberOfContainers${direction}TwentyFtEmpty`],
      visit[`talMismatch${direction}TwentyFtEmptyError`],
      visit[`btsNumberOfContainers${direction}TwentyFtFull`],
      visit[`talMismatch${direction}TwentyFtFullError`],
      visit[`tosNumberOfContainers${direction}TwentyFtEmpty`],
      visit[`tosNumberOfContainers${direction}TwentyFtFull`],
      direction.toLocaleLowerCase(),
      visit.thresholdMismatchBtsTos,
    ),
    formatTal(
      2,
      40,
      '40ft',
      visit[`btsNumberOfContainers${direction}FortyFtEmpty`],
      visit[`talMismatch${direction}FortyFtEmptyError`],
      visit[`btsNumberOfContainers${direction}FortyFtFull`],
      visit[`talMismatch${direction}FortyFtFullError`],
      visit[`tosNumberOfContainers${direction}FortyFtEmpty`],
      visit[`tosNumberOfContainers${direction}FortyFtFull`],
      direction.toLocaleLowerCase(),
      visit.thresholdMismatchBtsTos,
    ),
  ];

  const overviewUrl = props.page ? SITE_PATHS.VISIT_LIST + formatPasteableURL(pageToPageRequest(props.page)) : SITE_PATHS.VISIT_LIST;

  return visit ? (
    <Socket
      topics={[
        EVENT_TYPES.SPECIFIC_VISIT_STREAM.replace(':id', visit.id),
        EVENT_TYPES.SPECIFIC_CONTAINER_STREAM.replace(':id', visit.id),
        EVENT_TYPES.SPECIFIC_ERROR_STREAM.replace(':id', visit.id),
        EVENT_TYPES.SPECIFIC_LPL_REQUESTS_STREAM.replace(':id', visit.id),
      ]}
      onMessage={updateVisitDetail({ getDetailsAction: props.getDetailsAction, visit })}
      socketKey={`VisitDetail.${visit.id}`}
    >
      <Refresh
        rate={60000}
        refreshAction={() => {
          // eslint-disable-next-line react/no-access-state-in-setstate
          setShowContactInfo((prevState) => prevState); // trigger a rerender
        }}
      >
        <Row className="detail-row">
          <Col span={3}>
            <Link className="text-black" to={overviewUrl}>
              <LeftOutlined />
              <span className="text-underline">
                <FormattedMessage id="visit.detail.back" defaultMessage="Back to overview" />
              </span>
            </Link>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle" style={{ height: 52 }}>
          <Col span={2}>
            <span className="visit-detail-barge">{visit.barge && visit.barge.name ? visit.barge.name : ''}</span>
          </Col>
          <Col span={4}>
            <button type="button" className="visit-top-link" onClick={() => setShowContactInfo(true)}>
              <FormattedMessage id="visit.detail.contactInfo" defaultMessage="Contact info" />
            </button>{' '}
            <a className="visit-top-link" href={visit.b2bUrl} target="_blank" rel="noreferrer">
              <FormattedMessage id="visit.detail.b2b" defaultMessage="Open B2B" />
              <Elearning contentKey="DP_DETAIL_OPEN_B2B" align="right" />
            </a>
          </Col>
          <Col span={5} offset={13}>
            <div className="top-actions-menu-wrapper">
              <PriorityToggle visit={visit} priorityVisitAction={props.priorityVisitAction} />
              <ProcessToggle visit={visit} processVisitAction={props.processVisitAction} />
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginTop: 24 }}>
          <Col span={2} className="visit-detail-title">
            <FormattedMessage id="visit.detail.agent" defaultMessage="Agent" />
          </Col>
          <Col span={2} className="visit-detail-title">
            <FormattedMessage id="visit.detail.btsID" defaultMessage="BTS ID" />
          </Col>
          <Col span={8} className="visit-detail-title">
            <FormattedMessage id="visit.detail.rtaEta" defaultMessage="RTA/ETA" />
            <Elearning contentKey="DP_DETAIL_OPT_RTA_ETA" />
          </Col>
          <Col span={4} className="visit-detail-title">
            <FormattedMessage id="visit.detail.quay" defaultMessage="Quay" />
          </Col>
          <Col span={4} className="visit-detail-title">
            <FormattedMessage id="visit.detail.status" defaultMessage="Status" />
            <Elearning contentKey="DP_DETAIL_STATUS" />
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={2}>{visit.bargeOperator && visit.bargeOperator.name ? visit.bargeOperator.name : <FormattedMessage id="barge_operator_not_specified" defaultMessage="Not specified" />}</Col>
          <Col span={2}>{visit.btsId}</Col>
          <Col span={8}>
            {visit.operationsTime ? (
              <div>
                <span>{renderOPT(visit)}</span>
                <span style={{ marginLeft: 8 }}>{renderRTA(visit)}</span>
                <span style={{ marginLeft: 16 }}>{renderETA(visit)}</span>
              </div>
            ) : (
              <div>
                <span>{renderRTA(visit)}</span>
                <span style={{ marginLeft: 8 }}>{renderETA(visit)}</span>
              </div>
            )}
          </Col>
          <Col span={4}>
            <span>{renderQuayPlanned(visit)}</span>
            <span style={{ marginLeft: visit.quayPlanned ? 8 : 0 }}>{renderQuayRequested(visit)}</span>
          </Col>
          <Col span={2}>{renderStatus(visit.status)}</Col>
          <Col span={6} align="right" className="visit-detail-updated">
            <ClockCircleOutlined style={{ marginRight: 5 }} />
            <FormattedMessage id="visit.detail.update" defaultMessage="Most recently updated {duration}" values={{ duration: humanizeTimeStamp(visit.updatedAt) }} />
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }}>
          <div className="custom-panel">
            <Elearning contentKey="DP_DETAIL_TAL_BLOCK_HEADER" />
            <Row type="flex" justify="start" className="detail-row">
              <Col span={4}>
                <h3 style={{ display: 'inline-block' }}>
                  <FormattedMessage id="organisms.visits.visit_detail.panel.tal.header" defaultMessage="TAL" />
                </h3>
                <span style={{ marginLeft: 5 }} className="text-subtitle">
                  ({visit.btsNumberOfContainers})
                </span>
                <Tag color="red" style={{ marginLeft: 5 }}>
                  <ExclamationCircleOutlined className="text-red" />
                  <span style={{ marginLeft: 5 }}>{visit.talMismatch}</span>
                </Tag>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col span={8}>
                <div style={{ marginBottom: 8 }}>
                  <ArrowDownOutlined className="text-green" />
                  <FormattedMessage id="organisms.visits.visit_detail.unload" defaultMessage="Unload" />
                  <span className="text-dust" style={{ marginLeft: 5 }}>
                    (BTS: {visit.btsNumberOfContainersIn} TOS: {visit.tosNumberOfContainersIn})
                  </span>
                  <span style={{ marginLeft: 5 }}>
                    <ExclamationCircleOutlined className="text-red" />
                    <span style={{ marginLeft: 5 }} className="text-dust">
                      ({visit.talMismatchIn})
                    </span>
                  </span>
                </div>
                <TalList talList={formatTalList('In')} updateBTS={props.updateBTSAction} visitId={props.visit.id} />
              </Col>
              <Col span={8} style={{ paddingLeft: SPACING.COLLAPSE }}>
                <div style={{ marginBottom: 8 }}>
                  <ArrowUpOutlined className="text-red" />
                  <FormattedMessage id="organisms.visits.visit_detail.load" defaultMessage="Load" />
                  <span className="text-dust" style={{ marginLeft: 5 }}>
                    (BTS: {visit.btsNumberOfContainersOut} TOS: {visit.tosNumberOfContainersOut})
                  </span>
                  <span style={{ marginLeft: 5 }}>
                    <ExclamationCircleOutlined className="text-red" />
                    <span style={{ marginLeft: 5 }} className="text-dust">
                      ({visit.talMismatchOut})
                    </span>
                  </span>
                </div>
                <TalList talList={formatTalList('Out')} updateBTS={props.updateBTSAction} visitId={props.visit.id} />
              </Col>
            </Row>
          </div>
        </Row>

        {props.visit.psaVisit ? (
          <Row style={{ marginTop: 32 }}>
            <InternalErrorsCollapse
              errors={internalErrors}
              timestamp={props.visit.errorsSentAt}
              loading={loadingInternalErrors}
              archiveInternalErrorsAction={props.archiveInternalErrorsAction}
              unarchiveInternalErrorsAction={props.unarchiveInternalErrorsAction}
              b2bLink={props.visit.b2bUrl}
              timestampArchived={props.visit.intErrorsArchivedAt}
            />
          </Row>
        ) : null}

        {props.visit.psaVisit ? (
          <Row style={{ marginTop: 10 }}>
            <ExternalErrorsCollapse
              elearningContentKey="DP_DETAIL_EXT_ERRORS_BLOCK_HEADER"
              errors={props.externalErrors}
              mailLink={getSendErrorsOrNull()}
              timestamp={props.visit.errorsSentAt}
              timestampArchived={props.visit.extErrorsArchivedAt}
              loading={props.loadingExternalErrors}
              b2bLink={props.visit.b2bUrl}
              visitId={props.visit.id}
            />
          </Row>
        ) : null}

        {props.visit.psaVisit ? (
          <Row style={{ marginTop: 10 }}>
            <EmptiesYardCollapse
              defaultOpen={parameters.empties_yard}
              timestamp={props.visit.lplRequestGeneratedAt}
              createRemarksAction={props.createVisitRemarkAction}
              emptiesYardRemarks={props.LPLRemarks}
              loadingRemarks={props.loadingLPLRemarks}
              visit={props.visit}
            />
          </Row>
        ) : null}

        {props.visit.psaVisit ? (
          <Row style={{ marginTop: 10 }}>
            <ContainerCollapse
              containersOverlanded={props.visit.overlandedContainers}
              containersAfterCutOff={props.visit.containersAfterTalCutOff}
              elearningContentKey="DP_DETAIL_CONTAINERS_BLOCK_HEADER"
              page={props.containerPage}
              loadingPage={props.loadingContainerPage}
              getContainerVisitPageAction={props.getVisitContainerPageAction}
              visit={props.visit}
            />
          </Row>
        ) : null}

        <Row style={{ marginTop: 10 }}>
          <RemarkCollapse
            allowedRoles={[ROLES.ROLE_DATA_PROCESSOR]}
            btsRemark={props.visit.remarksBts}
            createRemarkAction={props.createVisitRemarkAction}
            loading={props.loadingVisitRemarks}
            remarks={props.visitRemarks}
            remarkType={REMARK_TYPES.VISIT}
            contentKey="DP_DETAIL_REMARKS_BLOCK_HEADER"
            visitId={props.visit.id}
          />
        </Row>

        <ContactInfoModal visit={props.visit} showDialog={showContactInfo} hideModal={() => setShowContactInfo(false)} />
      </Refresh>
    </Socket>
  ) : null;
}

export const VisitDetail = VisitDetailComponent
