// @flow
import * as React from 'react';
import { Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

type TProps = {
  buttonText: ?string,
  createRoute: ?string,
  title: string,
};

export function ListHeader(props: TProps) {
  const navigate = useNavigate();
  return (
    <Row className="master-header">
      <Col>
        <h1>{props.title}</h1>
        {props.createRoute && props.buttonText && (
          <Button type="default" onClick={() => navigate(props.createRoute)}>
            <PlusOutlined />
            {props.buttonText}
          </Button>
        )}
      </Col>
    </Row>
  );
}
