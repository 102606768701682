// @flow
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { elearningGetContentAction } from '../../../store/actions';
import { SITE_PATHS } from '../../../config/api.constants';
import { ElearningListTableProps } from './ElearningListTableProps';
import { createPath } from '../../../utils/routing.utils';

export type TElearningListTableProps = {
  keys: string[],
};

/**
 * We cannot extract all the e-learning keys dynamically from the whole application. So we hard
 * coded them in the config section. This enables us to abstract the table component showing
 * the content of all those labels. The only thing this table needs is a list of keys.
 *
 * The table properties and behaviour of the table is described in ElearningListTableProps.
 *
 * @param props
 * @returns {*}
 * @constructor
 */

function ElearningListTableComponent(props: TElearningListTableProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Process list content
  const { keys } = props;
  const data = keys.map((key) => {
    // Get the content from the state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const content = useSelector((state) => state.elearning.content[key]);
    if (content && !content.error) {
      return content;
    }
    return {
      key,
      title: '',
      body: '',
      editable: true,
    };
  });

  useEffect(() => {
    keys.forEach((key) => dispatch(elearningGetContentAction(key)));
  }, []);

  return (
    <Table
      columns={ElearningListTableProps()}
      dataSource={data}
      onRow={(record) => ({
        onClick: () => {
          if (record.error || record.editable) {
            navigate(createPath(SITE_PATHS.E_LEARNING_EDIT, { key: record.key }));
          }
        },
      })}
      rowKey="key"
    />
  );
}

export const ElearningListTable = injectIntl(ElearningListTableComponent);
