// @flow
import axios from 'axios';
import { log } from '../utils/log.util';
import { API_STATUS_CODE, BASE_URL, SITE_PATHS } from './api.constants';
import { API_ELEARNING_URL } from '../store/sagas/elearning.saga';
import { navigateTo } from '../utils/routing.utils';

export const appApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept-Language': 'en',
  },
  timeout: 30000,
});

appApi.interceptors.response.use(
  (response) => response,
  (error) => {
    log.api('error')(error);
    const status = error.response ? error.response.status : null;
    const url = error.request && error.request.responseURL;
    if (status === API_STATUS_CODE.NOT_FOUND) {
      if (url && url.indexOf(API_ELEARNING_URL) === -1) {
        navigateTo(SITE_PATHS.NOT_FOUND);
      }
    }
    return Promise.reject(error);
  },
);
