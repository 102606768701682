// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { editQuayAction, getQuayAction } from '../../../store/actions';
import type { TGlobalState, TQuay } from '../../../types';
import QuayForm from '../../organisms/quays/CreateForm';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';
import { Params } from 'react-router-dom';

type TProps = {
  editQuayAction: typeof editQuayAction,
  getQuayAction: typeof getQuayAction,
  intl: intlShape,
  loading: boolean,
  match: any,
  quay: TQuay,
  params: Params<String>,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.quay.edit.header.title', defaultMessage: 'Edit {name}' },
});

class QuayEdit extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.params;
    this.props.getQuayAction(id);
  }

  render() {
    if (!this.props.quay) return null;

    return (
      <EditTemplate title={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.title, { name: this.props.quay.name })} loading={this.props.loading} menuBlock="quays" menuItem="quays-overview">
        <QuayForm handleSubmit={this.props.editQuayAction} quay={this.props.quay} />
      </EditTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  loading: state.quays.loadingDetail,
  quay: state.quays.detail,
});

export const QuayEditPage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, { editQuayAction, getQuayAction })(QuayEdit));
