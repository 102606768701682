// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constants/pagination.constants';
import { skipperListSelector } from '../../../store/selectors/skipper.selectors';
import { deleteSkipperAction, getSkipperPageAction } from '../../../store/actions';
import { SimpleTable } from '../../atoms/Tables/SimpleTable';
import { SkipperTableProps } from '../../atoms/Tables/SkipperTableProps';
import { ListTemplate } from '../../templates/MasterData/ListTemplate';
import { SITE_PATHS } from '../../../config/api.constants';
import { createPath } from '../../../utils/routing.utils';
import { useNavigate } from 'react-router-dom';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  button: { id: 'pages.skipper.list.header.button', defaultMessage: 'Add new skipper' },
  title: { id: 'pages.skipper.list.header.title', defaultMessage: 'Skippers' },
});

function SkipperListPageComponent(props: TProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(skipperListSelector);

  const loadPage = (page: number, pageSize: number) => {
    dispatch(getSkipperPageAction({ filter: {}, paging: { size: pageSize, number: page } }));
  };
  const goToDetail = (record) => navigate(createPath(SITE_PATHS.SKIPPER_DETAIL, { id: record.id }));
  const deleteRow = (record) => dispatch(deleteSkipperAction(record));

  useEffect(() => {
    loadPage(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
  }, [dispatch]);

  return (
    <ListTemplate
      buttonText={props.intl.formatMessage(EXTRA_TRANSLATIONS.button)}
      createRoute={SITE_PATHS.SKIPPER_CREATE}
      title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)}
      menuBlock="skippers"
      menuItem="skippers-overview"
    >
      <SimpleTable onRowClick={goToDetail} onPageChange={loadPage} columns={SkipperTableProps(deleteRow)} data={data}
                   rowKey="id" className="skipper-table-list" />
    </ListTemplate>
  );
}

export const SkipperListPage = injectIntl(SkipperListPageComponent);
