// @flow
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState } from '../../../../types';

const { RangePicker } = DatePicker;

type TProps = {
  defaultValue: moment[],
  filterProp: string,
  filterType: string,
  search: Function,
};

type TState = {
  defaultValue: moment[],
  value: moment[],
  inFocus: boolean,
};

const isValidRange = (values: Moment[]) => {
  let validDates = true;
  values.forEach((date) => {
    validDates = !!(validDates && date.isValid());
  });

  return validDates;
};

class FilterRangeForm extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      defaultValue: this.getDefaultValue(this.props.defaultValue),
      value: this.getDefaultValue(this.props.defaultValue) || undefined,
    };
  }

  static getDerivedStateFromProps(props: TProps, state: TState) {
    const newState = { ...state };
    newState.defaultValue = isValidRange(props.defaultValue) ? props.defaultValue : null;
    if (!state.inFocus && props.defaultValue) {
      newState.value = isValidRange(props.defaultValue) ? props.defaultValue : null;
    }
    return newState;
  }

  getDefaultValue = (dates: moment[]) => {
    return isValidRange(dates) ? dates : null;
  };

  handleSearch = (dates: any) => {
    // this is a workaround to check if the clear button was clicked
    // and to reset to the initial values rather than resetting to empty
    // input (as antd does by default)
    if (!dates) {
      this.setState({ defaultValue: this.getDefaultValue(this.props.defaultValue) });
      this.props.search({ from: null, to: null }, this.props.filterProp);
      return;
    }
    const dateObject = { from: dates[0] && dates[0].format(), to: dates[1] && dates[1].format() };
    this.props.search(dateObject, this.props.filterProp);
    this.setState({ value: this.getDefaultValue(dates) });
  };

  render() {
    const dateFormat = 'DD/MM/YYYY HH:mm';

    return (
      <div className="custom-filter-range">
        <RangePicker
          defaultValue={this.state.defaultValue ? [moment(this.state.defaultValue[0], dateFormat), moment(this.state.defaultValue[1], dateFormat)] : null}
          format={dateFormat}
          onChange={this.handleSearch}
          value={this.state.value}
          {
            ...{
              /* only when there is a default value we have operational times available, no need to show this footer if we do not have these timeslots */
            }
          }
          renderExtraFooter={() => this.state.defaultValue && <span style={{ color: '#00000040' }}>Uren volgen de operationele tijdstippen en zijn niet aanpasbaar.</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: TGlobalState, ownProps: TProps) => ({
  defaultValue: [
    moment(state[ownProps.filterType].page && state[ownProps.filterType].page.filter && state[ownProps.filterType].page.filter[ownProps.filterProp].from),
    moment(state[ownProps.filterType].page && state[ownProps.filterType].page.filter && state[ownProps.filterType].page.filter[ownProps.filterProp].to),
  ],
});

export const FilterRange = connect<any, Object, _, _, _, _>(mapStateToProps, undefined)(FilterRangeForm);
