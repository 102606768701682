// @flow
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { NOTIFICATION_TYPES } from '../../constants/notification.constants';
import type {
  TBaseAction,
  TErrorCode,
  TErrorCodeEditForm,
  TErrorCodeForm,
  TGlobalState,
  TPageRequest,
} from '../../types';
import { genericErrorHandler } from '../../utils';
import {
  ErrorCodeActions,
  getErrorCodePageAction,
  receivedErrorCodeAction,
  receivedErrorCodePageAction,
} from '../actions/error_code.actions';
import { API_PATHS, API_STATUS_CODE, SITE_PATHS } from '../../config/api.constants';
import { appApi } from '../../config/api.config';
import { createPath, navigateTo } from '../../utils/routing.utils';
import { showNotificationAction } from '../actions/notificationActions';
import { formatUri } from '../../utils/format.utils';

export function* create(action: TBaseAction<TErrorCodeForm>): Generator<*, *, *> {
  const { payload, callback } = action;
  try {
    yield call(appApi.post, API_PATHS.V1.ERROR_CODE, payload);
    navigateTo(SITE_PATHS.ERROR_CODE_LIST);
  } catch (err) {
    const { response } = err;
    if (response && response.status === API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      const errors = [];
      const { validationErrors } = response.data;

      if ('code' in validationErrors) {
        errors.push('code');
      }

      if (callback) {
        callback(errors);
      }
    }
  }
}

export function* detail(action: TBaseAction<number>): Generator<*, *, *> {
  const { payload } = action;
  const response = yield call(appApi.get, `${API_PATHS.V1.ERROR_CODE}/${payload}`);
  yield put(receivedErrorCodeAction(response.data));
}

export function* edit(action: TBaseAction<TErrorCodeEditForm>): Generator<*, *, *> {
  const { payload, callback } = action;

  try {
    const response = yield call(appApi.put, `${API_PATHS.V1.ERROR_CODE}/${payload.id}`, payload);

    yield put(receivedErrorCodeAction(response.data));
    navigateTo(createPath(SITE_PATHS.ERROR_CODE_DETAIL, { id: payload.id }));
  } catch (err) {
    const { response } = err;
    if (response && response.status === API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      const errors = [];
      const { validationErrors } = response.data;

      if ('code' in validationErrors) {
        errors.push('code');
      }

      if (callback) {
        callback(errors);
      }
    }
  }
}

export function* page(action: TBaseAction<?TPageRequest<null>>): Generator<*, *, *> {
  const { payload } = action;
  const uri = action.payload ? formatUri(API_PATHS.V1.ERROR_CODE, payload) : API_PATHS.V1.ERROR_CODE;
  const response = yield call(appApi.get, uri);

  yield put(receivedErrorCodePageAction(response.data));
}

export function* remove(action: TBaseAction<TErrorCode>): Generator<*, *, *> {
  const { payload } = action;
  const response = yield call(appApi.delete, `${API_PATHS.V1.ERROR_CODE}/${payload.id}`);

  if (response.status === API_STATUS_CODE.SUCCESS_NO_CONTENT) {
    yield put(showNotificationAction(NOTIFICATION_TYPES.ERROR_CODE_DELETE));
  }

  const page = yield select((state: TGlobalState) => state.errorCodes.list);
  yield put(getErrorCodePageAction({ filter: {}, paging: { size: page.size, number: page.number } }));
}

export function* ErrorCodeSaga(): Generator<*, *, *> {
  yield takeLatest(ErrorCodeActions.create, genericErrorHandler(create));
  yield takeLatest(ErrorCodeActions.detail, genericErrorHandler(detail));
  yield takeLatest(ErrorCodeActions.edit, genericErrorHandler(edit));
  yield takeLatest(ErrorCodeActions.page, genericErrorHandler(page));
  yield takeLatest(ErrorCodeActions.remove, genericErrorHandler(remove));
}
