// @flow
import qs from 'qs';
import { queryStringDecoder } from './general.utils';

export const createPath = (path: string, pathVars: any, queryString?: Object) => {
  let newPath = path;
  if (newPath && pathVars) {
    Object.keys(pathVars).map((key) => {
      newPath = newPath.replace(`:${key}`, pathVars[key]);
      return key;
    });

    if (queryString) {
      newPath = `${newPath}?${qs.stringify(queryString)}`;
    }

    return newPath;
  }
  return '/error';
};
export const parseUrlQuery = (url: string) =>
  qs.parse(url, {
    ignoreQueryPrefix: true,
    // Serializes arrays and variables immediately to integers.
    decoder: queryStringDecoder,
  });

export const navigateTo = (path: string) => window.location.href = '/#' + path;
