// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { SITE_PATHS } from '../../../config/api.constants';
import { getBargeOperatorAction } from '../../../store/actions';
import { getErrorCodeAction, removeErrorCodeAction } from '../../../store/actions/error_code.actions';
import type { TErrorCode, TGlobalState } from '../../../types';
import { getNumber, getString } from '../../../utils/general.utils';
import { GeneralDetailTable } from '../../atoms/Tables/MDDetailTables/GeneralDetailTableColumns';
import { DetailTemplate } from '../../templates/MasterData/DetailTemplate';
import { Params } from 'react-router-dom';

type TProps = {
  errorCode: TErrorCode,
  getErrorCodeAction: typeof getBargeOperatorAction,
  intl: intlShape,
  loading: boolean,
  match: any,
  removeErrorCodeAction: typeof removeErrorCodeAction,
  params: Params<String>,
};

const EXTRA_TRANSLATIONS = defineMessages({
  code: { id: 'errorcode.code', defaultMessage: 'Code' },
  type: { id: 'errorcode.type', defaultMessage: 'type' },
  description: { id: 'errorcode.description', defaultMessage: 'description' },
});

class ErrorCodeDetail extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.params;
    this.props.getErrorCodeAction(id);
  }

  extractErrorCodeDetails = (errorCode: TErrorCode) => [
    {
      label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.code),
      value: errorCode.code,
    },
    {
      label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.type),
      value: errorCode.type,
    },
    {
      label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.description),
      value: errorCode.description,
    },
  ];

  render() {
    return (
      <DetailTemplate
        backRoute={SITE_PATHS.ERROR_CODE_LIST}
        deleteAction={this.props.removeErrorCodeAction}
        editRoute={SITE_PATHS.ERROR_CODE_UPDATE}
        entity={this.props.errorCode}
        headerTitle={`${getString(() => this.props.errorCode.code)}`}
        id={getNumber(() => this.props.errorCode.id)}
        loading={this.props.loading}
        menuBlock="error-codes"
        menuItem="error-codes-overview"
      >
        {this.props.errorCode ? <GeneralDetailTable details={this.extractErrorCodeDetails(this.props.errorCode)} /> : null}
      </DetailTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  errorCode: state.errorCodes.detail,
  loading: state.errorCodes.loadingDetail,
});

export const ErrorCodeDetailPage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, { getErrorCodeAction, removeErrorCodeAction })(ErrorCodeDetail));
