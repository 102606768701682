// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Params } from 'react-router-dom';
import { getBargeOperatorAction, removeBargeOperatorAction } from '../../../store/actions/barge_operator.actions';
import type { TBargeOperator } from '../../../types/barge_operator.types';
import { GeneralDetailTable } from '../../atoms/Tables/MDDetailTables/GeneralDetailTableColumns';
import { DetailTemplate } from '../../templates/MasterData/DetailTemplate';
import { SITE_PATHS } from '../../../config/api.constants';
import type { TGlobalState } from '../../../types';
import { ContactDetailTable } from '../../atoms/Tables/MDDetailTables/ContactDetailTable';
import { UnavailabilityWindowDetailTable } from '../../atoms/Tables/MDDetailTables/UnavailabilityWindowDetailTable';
import { getArray, getNumber, getString } from '../../../utils/general.utils';

type TProps = {
  bargeOperator: TBargeOperator,
  getBargeOperatorAction: typeof getBargeOperatorAction,
  loading: boolean,
  match: any,
  removeBargeOperatorAction: typeof removeBargeOperatorAction,
  params: Params<String>,
};

class BargeOperatorDetail extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.params;
    this.props.getBargeOperatorAction(id);
  }

  extractDetails = (bargeOperator: TBargeOperator) => {
    const details = [];
    if (!bargeOperator) return details;

    if (bargeOperator.reference)
      details.push({
        label: 'Carrier ID',
        value: bargeOperator.reference,
      });

    return details;
  };

  render() {
    return (
      <DetailTemplate
        backRoute={SITE_PATHS.BARGE_OPERATOR_LIST}
        deleteAction={this.props.removeBargeOperatorAction}
        editRoute={SITE_PATHS.BARGE_OPERATOR_UPDATE}
        entity={this.props.bargeOperator}
        headerTitle={`${getString(() => this.props.bargeOperator.name)} (${getString(() => this.props.bargeOperator.abbreviation)})`}
        id={getNumber(() => this.props.bargeOperator.id)}
        loading={this.props.loading}
        menuItem="barge-operators-overview"
        menuBlock="barge-operators"
      >
        <GeneralDetailTable details={this.extractDetails(this.props.bargeOperator)} />
        <ContactDetailTable contactDetails={getArray(() => this.props.bargeOperator.contactDetails)} />
        <UnavailabilityWindowDetailTable unavailabilityWindows={getArray(() => this.props.bargeOperator.unavailabilityWindows)} />
      </DetailTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  bargeOperator: state.bargeOperators.detail,
  loading: state.bargeOperators.loadingDetail,
});

export const BargeOperatorDetailPage = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getBargeOperatorAction,
  removeBargeOperatorAction,
})(BargeOperatorDetail);
