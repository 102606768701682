// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import type { TGlobalState, TPageWithFilter, TQuay } from '../../../types';
import { getQuayPageAction, removeQuayAction } from '../../../store/actions';
import { ListTemplate } from '../../templates/MasterData/ListTemplate';
import { SITE_PATHS } from '../../../config/api.constants';
import { Table } from '../../atoms/Tables/Table';
import { QuayTableProps } from '../../atoms/Tables/QuayTableProps';
import { createPath } from '../../../utils/routing.utils';

type TProps = {
  getQuayPageAction: typeof getQuayPageAction,
  intl: intlShape,
  loading: boolean,
  page: TPageWithFilter<TQuay>,
  removeQuayAction: typeof removeQuayAction,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.quays.list.header.title', defaultMessage: 'Quay' },
  button: { id: 'pages.quays.list.header.button', defaultMessage: 'Add new quay' },
});

function QuayList(props: TProps) {
  const navigate = useNavigate();

  return (
    <ListTemplate
      buttonText={props.intl.formatMessage(EXTRA_TRANSLATIONS.button)}
      createRoute={SITE_PATHS.QUAY_CREATE}
      title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)}
      menuBlock="quays"
      menuItem="quays-overview"
    >
      <Table
        columns={QuayTableProps(props.removeQuayAction)}
        rowKey="id"
        onRow={(record) => ({
          onClick: () =>
            navigate(createPath(SITE_PATHS.QUAY_DETAIL, { id: record.id })),
        })}
        page={props.page}
        getPageAction={props.getQuayPageAction}
        loading={props.loading}
      />
    </ListTemplate>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  loading: state.quays.loadingPage,
  page: state.quays.page,
});

export const QuayListPage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, {
  getQuayPageAction,
  removeQuayAction,
})(QuayList));
