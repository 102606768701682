// @flow
import * as React from 'react';
import { Dropdown } from 'antd';
import { FormattedMessage } from 'react-intl';
import { API_PATHS, SITE_PATHS } from '../../../config/api.constants';
import type { TVisit } from '../../../types';
import { priorityVisitAction, processVisitAction } from '../../../store/actions';
import { createPath } from '../../../utils/routing.utils';
import { useNavigate } from 'react-router-dom';

type TProps = {
  active: boolean,
  priorityVisitAction: typeof priorityVisitAction,
  processVisitAction: typeof processVisitAction,
  setMenuActive: Function,
  setMenuInactive: Function,
  showModal: Function,
  visit: TVisit,
};

export function ContextMenu(props: TProps) {
  const navigate = useNavigate();
  const menuItems = [
    {
      label: <FormattedMessage id="molecules.visits.contextmenu.contact_details" defaultMessage="Open contact info" />,
      key: 'open contact info',
    },
    {
      label: <FormattedMessage id="molecules.visits.contextmenu.open_in_b2b" defaultMessage="Open visit in B2B" />,
      key: 'open in b2b',
    },
    {
      label: <FormattedMessage id="molecules.visits.contextmenu.generate_lpl_request" defaultMessage="Generate LPL request" />,
      key: 'generate lpl request',
    },
    {
      label: props.visit.processed ? (
        <FormattedMessage id="molecules.visits.contextmenu.mark_processed" defaultMessage="Mark as unprocessed" />
      ) : (
        <FormattedMessage id="molecules.visits.contextmenu.mark_unprocessed" defaultMessage="Mark as processed" />
      ),
      key: 'process visit',
    },
    {
      label: props.visit.priority ? (
        <FormattedMessage id="molecules.visits.contextmenu.make_non_priority" defaultMessage="Mark as non-priority" />
      ) : (
        <FormattedMessage id="molecules.visits.contextmenu.make_priority" defaultMessage="Mark as priority" />
      ),
      key: 'change priority',
    },
  ];

  const handleMenuClick = (key, domEvent) => {
    domEvent.stopPropagation();
    // eslint-disable-next-line default-case
    switch (key) {
      case 'open contact info':
        props.showModal();
        break;
      case 'open in b2b':
        window.open(props.visit.b2bUrl);
        break;
      case 'generate lpl request':
        navigate(createPath(SITE_PATHS.VISIT_DETAIL, { id: props.visit.id }, { empties_yard: 'true' }));
        break;
      case 'process visit':
        props.processVisitAction(props.visit);
        break;
      case 'change priority':
        props.priorityVisitAction({
          newPriority: !props.visit.priority,
          previousPriority: props.visit.priority,
          link: createPath(API_PATHS.V1.VISIT_PRIORITY, { id: props.visit.id, value: !props.visit.priority }),
        });
        break;
    }
    props.setMenuInactive();
  };

  return (
    <Dropdown
      menu={{ items: menuItems, onClick: ({ key, domEvent }) => handleMenuClick(key, domEvent) }}
      trigger={['click']}
      placement="bottomLeft"
      onClick={(ev) => ev.stopPropagation()}
      onOpenChange={(visible: boolean) => (visible ? props.setMenuActive() : props.setMenuInactive())}
    >
      <div className="context-wrapper">
        <div className={`context-menu ${props.active ? 'active' : ''}`}>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    </Dropdown>
  );
}
