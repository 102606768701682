// @flow
import { Col, Modal, Row } from 'antd';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { CONTACT_TYPES } from '../../../constants/contact.constants';
import type { TContactDetail, TGlobalState, TVisit } from '../../../types';
import { ContactInfo } from '../contact_info/ContactInfo';
import { getVisitContactDetailsAction } from '../../../store/actions';
import type { TAllContactDetails } from '../../../types/contact_details.types';
import { Loading } from '../../atoms/Loading';
import { extractContactInfo } from '../../../utils/visit.utils';

export type TProps = {
  hideModal: Function,
  intl: intlShape,
  showDialog: boolean,
  visit: TVisit,
};

export type TContact = {
  available: boolean,
  contactInfo: TContactDetail[],
  id: number,
  type: string,
};

type TContactInfoModalContentProps = {
  allContactDetails: TAllContactDetails,
  getVisitContactDetailsAction: typeof getVisitContactDetailsAction,
  intl: intlShape,
  loadingContactDetails: boolean,
  visit: TVisit,
};

const EXTRA_TRANSLATIONS = defineMessages({
  skipper: {
    defaultMessage: 'Skipper',
    id: 'contact.info.modal.skipper',
  },
  barge_operator: {
    defaultMessage: 'Barge Operator',
    id: 'contact.info.modal.barge.operator',
  },
  contact_info: {
    defaultMessage: 'Contact info',
    id: 'contact.info.modal',
  },
});

class ContactInfoModalContentComponent extends React.Component<TContactInfoModalContentProps> {
  componentDidMount() {
    this.props.getVisitContactDetailsAction(this.props.visit);
  }

  render() {
    const allContacts: TContact[] = extractContactInfo(this.props.allContactDetails);

    const renderName = (type: string) => {
      switch (type) {
        case CONTACT_TYPES.SKIPPER:
          return this.props.intl.formatMessage(EXTRA_TRANSLATIONS.skipper);
        case CONTACT_TYPES.BARGE_OPERATOR:
          return this.props.intl.formatMessage(EXTRA_TRANSLATIONS.barge_operator);
        default:
          return null;
      }
    };

    const renderContactInfo = (contact: TContact) => <ContactInfo id={contact.id} available={contact.available} contactInfo={contact.contactInfo || []} name={renderName(contact.type)} />;

    const renderContacts = (contacts: TContact[]) => {
      if (contacts.length === 0) {
        return (
          <Row>
            <Col span={24} style={{ padding: 8 }}>
              <FormattedMessage id="contact.info.modal.noData" defaultMessage="No contact information available" />
            </Col>
          </Row>
        );
      }

      const arrays = [];
      const arraySize = 2;
      // $FlowFixMe
      contacts.sort((a: TContact, b: TContact) => a.available - b.available); // Javascript type magic
      for (let i = 0; i < contacts.length; i += arraySize) {
        arrays.push(contacts.slice(i, i + arraySize));
      }
      return arrays.map((row: TContact[]) => (
        <Row key={row[0].id}>
          {row.map((contact) => (
            <Col key={contact.id} span={12}>
              {renderContactInfo(contact)}
            </Col>
          ))}
        </Row>
      ));
    };

    return this.props.loadingContactDetails ? <Loading /> : renderContacts(allContacts);
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  loadingContactDetails: state.visits.loadingContactDetails,
  allContactDetails: state.visits.contactDetails,
});

const ContactInfoModalContent = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getVisitContactDetailsAction,
})(ContactInfoModalContentComponent);

function ContactInfoModalComponent(props: TProps) {
  return (
    <Modal
      title={props.intl.formatMessage(EXTRA_TRANSLATIONS.contact_info)}
      centered
      open={props.showDialog}
      footer={null}
      onOk={props.hideModal}
      onCancel={props.hideModal}
      maskClosable
      width={700}
      destroyOnClose
    >
      <ContactInfoModalContent visit={props.visit} intl={props.intl} />
    </Modal>
  );
}

export const ContactInfoModal = injectIntl(ContactInfoModalComponent);
