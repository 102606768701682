// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { SITE_PATHS } from '../../config/api.constants';
import { ROLES } from '../../constants/roles.constants';
import type { TGlobalState } from '../../types';

type TProps = {
  roles: string[],
  navigate: Function,
};

class AppComponent extends React.Component<TProps> {
  constructor(props) {
    super(props);
    this.state = {
      pathToRedirect: undefined
    }
  }

  componentDidMount(): * {
    const roles = this.props.roles || [];

    if (roles.includes(ROLES.ROLE_DATA_PROCESSOR)) {
      this.setState({pathToRedirect :SITE_PATHS.VISIT_LIST});
    } else if (roles.includes(ROLES.ROLE_MASTER_DATA_MANAGEMENT)) {
      this.setState({pathToRedirect :SITE_PATHS.MASTER_DATA_HOME});
    } else if (roles.includes(ROLES.ROLE_EMPTIES_YARD)) {
      this.setState({pathToRedirect :SITE_PATHS.LPL_LIST});
    } else if (roles.includes(ROLES.ROLE_LICHTER_BEDIENDE)) {
      this.setState({pathToRedirect :SITE_PATHS.BARGE_CLERK_LIST});
    } else if (roles.includes(ROLES.ROLE_OPERATIONS_MANAGER)) {
      this.setState({pathToRedirect :SITE_PATHS.BARGE_CLERK_LIST});
    } else {
      this.setState({pathToRedirect :SITE_PATHS.UNAUTHORIZED});
    }
  }

  render() {
    return <Navigate to={this.state.pathToRedirect}/>
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  roles: state.user.bootstrap.profile.roles,
});

export const Page = connect<any, Object, _, _, _, _>(mapStateToProps, {})(AppComponent);
