// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { availableVisitAction, priorityVisitAction, setIdleTimeReasonAction } from '../../../store/actions';
import type { TFilterObject, TGlobalState, TPageWithFilter, TVisit, TVisitFilter } from '../../../types';
import { VisitListTable } from './VisitListTable';
import { refreshAction } from '../../../store/actions/socket.actions';
import {
  getBargeClerkPageAction,
  getBargeFilterAction,
  getBtsIdFilterAction,
  getSpecialContainerFilterAction,
  getStatusFilterAction,
  receivedVisitFilterAction,
} from '../../../store/actions/barge_clerk.actions';
import withRouter from '../../../utils/withRouter';

type TProps = {
  getBargeClerkPageAction: typeof getBargeClerkPageAction,
  getSpecialContainerFilterAction: typeof getSpecialContainerFilterAction,
  getStatusFilterAction: typeof getStatusFilterAction,
  loadingPage: boolean,
  page: TPageWithFilter<TVisit, TVisitFilter>,
  refreshAction: typeof refreshAction,
  setIdleTimeReasonAction: typeof setIdleTimeReasonAction,
  priorityVisitAction: typeof priorityVisitAction,
  availableVisitAction: typeof availableVisitAction,
  getBargeFilterAction: typeof getBargeFilterAction,
  getBtsIdFilterAction: typeof getBtsIdFilterAction,
  updateUrl: Function,
  url: Object,
  userRoles: string[],
};

function VisitListTableContainerComponent(props: TProps) {
  const handleSearch = (selectedValues: string[] | TFilterObject[], filterProp: string) => {
    const { filter } = props.page;
    const { pageable, sort } = props.page.data;

    if (!filter) {
      throw Error('Filter values should be provided.');
    }

    const newFilter: TVisitFilter = { ...filter };
    newFilter[filterProp] = selectedValues;
    newFilter.lbSummary = false;
    props.updateUrl({ filter: newFilter, pageable, sort });
  };

  const clearFilter = () => {
    props.updateUrl({ filter: {} });
  };

  const updateData = (pagination: any, filters: any, sorter: any) => {
    const pageInfo = {
      filter: props.page.filter,
      paging: { number: pagination.current - 1, size: props.page.data.pageable.pageSize },
      sorting: { by: [sorter.columnKey], order: sorter.order },
    };
    receivedVisitFilterAction(pageInfo);
    props.updateUrl(pageInfo);
  };

  return (
    <VisitListTable
      userRoles={props.userRoles}
      clearFilter={clearFilter}
      handleSearch={handleSearch}
      updateData={updateData}
      loadingPage={props.loadingPage}
      page={props.page}
      getBargeClerkPageAction={props.getBargeClerkPageAction}
      setIdleTimeReasonAction={props.setIdleTimeReasonAction}
      priorityVisitAction={props.priorityVisitAction}
      getStatusFilterAction={props.getStatusFilterAction}
      availableVisitAction={props.availableVisitAction}
      getBargeFilterAction={props.getBargeFilterAction}
      getBtsIdFilterAction={props.getBtsIdFilterAction}
      refreshAction={props.refreshAction}
      url={props.url}
      getSpecialContainerFilterAction={props.getSpecialContainerFilterAction}
    />
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  loadingPage: state.bargeClerks.loadingPage,
  page: state.bargeClerks.page,
  userRoles: state.user.bootstrap.profile.roles,
});

export const VisitListTableContainer = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getBargeClerkPageAction,
  setIdleTimeReasonAction,
  receivedVisitFilterAction,
  priorityVisitAction,
  availableVisitAction,
  getStatusFilterAction,
  getSpecialContainerFilterAction,
  getBargeFilterAction,
  getBtsIdFilterAction,
  refreshAction,
})(withRouter(VisitListTableContainerComponent));
