// @flow
import React from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Authorization } from '../components/atoms/Authorizer';
import NotificationManager from '../components/molecules/NotificationManager';
import {
  BargeCreatePage,
  BargeDetailPage,
  BargeEditPage,
  BargeListPage,
  BargeOperatorCreatePage,
  BargeOperatorDetailPage,
  BargeOperatorEditPage,
  BargeOperatorListPage,
  ConfigurationPage,
  DiagnosticsPage,
  ErrorCodeCreatePage,
  ErrorCodeDetailPage,
  ErrorCodeEditPage,
  LaundryCodeListPage,
  NotFoundPage,
  Page,
  QuayCreatePage,
  QuayDetailPage,
  QuayEditPage,
  QuayListPage,
  SkipperCreatePage,
  SkipperDetailPage,
  SkipperEditPage,
  SkipperListPage,
  VisitDetailPage,
  VisitListPage,
} from '../components/pages';
import { BargeClerkDetailPage } from '../components/pages/barge_clerks/Detail';
import { BargeClerkOverviewPage } from '../components/pages/barge_clerks/Overview';
import { LPLDetailPage } from '../components/pages/lpl/LPLDetail';
import { LPLListPage } from '../components/pages/lpl/LPLList';
import { MasterData } from '../components/pages/MasterData';
import ScrollToTop from '../utils/ScrollToTop';
import { ROLES } from '../constants/roles.constants';
import { SITE_PATHS } from './api.constants';
import { UnauthorizedPage } from '../components/pages/Unauthorized';
import { ElearningEditPage } from '../components/pages/elearning/ElearningEdit';
import { ElearningDpOverviewListPage } from '../components/pages/elearning/ElearningDpOverviewList';
import { ElearningLbOverviewListPage } from '../components/pages/elearning/ElearningLbOverviewList';
import { ElearningDpDetailListPage } from '../components/pages/elearning/ElearningDpDetailList';
import { ElearningLbDetailListPage } from '../components/pages/elearning/ElearningLbDetailList';
import { ErrorFallBackComponent } from '../components/pages/ErrorFallback';
import withRouter from '../utils/withRouter';

type TRoute = {
  childRoutes?: TRoute[],
  component: React.ComponentType<any>,
  exact: boolean,
  path: string,
  [key: string]: any
};

const masterDataPages = Authorization([ROLES.ROLE_MASTER_DATA_MANAGEMENT, ROLES.ROLE_DATA_PROCESSOR]);
const masterDataManipulation = Authorization([ROLES.ROLE_MASTER_DATA_MANAGEMENT]);
const lplEmptiesYardManagement = Authorization([ROLES.ROLE_EMPTIES_YARD]);
const lplEmptiesYardManagementDetail = Authorization([ROLES.ROLE_EMPTIES_YARD, ROLES.ROLE_LICHTER_BEDIENDE]);
const bargeClerkManagement = Authorization([ROLES.ROLE_LICHTER_BEDIENDE, ROLES.ROLE_OPERATIONS_MANAGER]);
const dataProcessorManagement = Authorization([ROLES.ROLE_DATA_PROCESSOR]);

const routes: TRoute[] = [
  {
    path: SITE_PATHS.HOME,
    component: Page,
    exact: true,
  },
  {
    path: SITE_PATHS.MASTER_DATA_HOME,
    component: masterDataPages(MasterData),
    exact: true,
  },
  {
    path: SITE_PATHS.SKIPPER_LIST,
    component: masterDataPages(SkipperListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.SKIPPER_CREATE,
    component: masterDataManipulation(SkipperCreatePage),
    exact: true,
  },
  {
    path: SITE_PATHS.SKIPPER_DETAIL,
    component: masterDataPages(SkipperDetailPage),
    exact: true,
  },
  {
    path: SITE_PATHS.SKIPPER_UPDATE,
    component: masterDataPages(SkipperEditPage),
    exact: true,
  },
  {
    path: SITE_PATHS.VISIT_LIST,
    component: dataProcessorManagement(VisitListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.VISIT_DETAIL,
    component: dataProcessorManagement(VisitDetailPage),
    exact: true,
  },
  {
    path: SITE_PATHS.CONFIGURATION,
    component: masterDataManipulation(ConfigurationPage),
    exact: true,
  },
  {
    path: SITE_PATHS.DIAGNOTICS,
    component: DiagnosticsPage,
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_LIST,
    component: masterDataPages(BargeListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_CREATE,
    component: masterDataManipulation(BargeCreatePage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_DETAIL,
    component: masterDataPages(BargeDetailPage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_UPDATE,
    component: masterDataManipulation(BargeEditPage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_OPERATOR_LIST,
    component: masterDataPages(BargeOperatorListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_OPERATOR_CREATE,
    component: masterDataManipulation(BargeOperatorCreatePage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_OPERATOR_DETAIL,
    component: masterDataPages(BargeOperatorDetailPage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_OPERATOR_UPDATE,
    component: masterDataManipulation(BargeOperatorEditPage),
    exact: true,
  },
  {
    path: SITE_PATHS.ERROR_CODE_LIST,
    component: masterDataPages(LaundryCodeListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.ERROR_CODE_CREATE,
    component: masterDataManipulation(ErrorCodeCreatePage),
    exact: true,
  },
  {
    path: SITE_PATHS.ERROR_CODE_DETAIL,
    component: masterDataPages(ErrorCodeDetailPage),
    exact: true,
  },
  {
    path: SITE_PATHS.ERROR_CODE_UPDATE,
    component: masterDataManipulation(ErrorCodeEditPage),
    exact: true,
  },
  {
    path: SITE_PATHS.E_LEARNING_DP_OVERVIEW_LIST,
    component: masterDataPages(ElearningDpOverviewListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.E_LEARNING_DP_DETAIL_LIST,
    component: masterDataPages(ElearningDpDetailListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.E_LEARNING_LB_OVERVIEW_LIST,
    component: masterDataPages(ElearningLbOverviewListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.E_LEARNING_LB_DETAIL_LIST,
    component: masterDataPages(ElearningLbDetailListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.E_LEARNING_EDIT,
    component: masterDataPages(ElearningEditPage),
    exact: true,
  },
  {
    path: SITE_PATHS.QUAY_LIST,
    component: masterDataPages(QuayListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.QUAY_CREATE,
    component: masterDataManipulation(QuayCreatePage),
    exact: true,
  },
  {
    path: SITE_PATHS.QUAY_DETAIL,
    component: masterDataPages(QuayDetailPage),
    exact: true,
  },
  {
    path: SITE_PATHS.QUAY_UPDATE,
    component: masterDataManipulation(QuayEditPage),
    exact: true,
  },
  {
    path: SITE_PATHS.LPL_DETAIL,
    component: lplEmptiesYardManagementDetail(LPLDetailPage),
    exact: true,
  },
  {
    path: SITE_PATHS.LPL_LIST,
    component: lplEmptiesYardManagement(LPLListPage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_CLERK_LIST,
    component: bargeClerkManagement(BargeClerkOverviewPage),
    exact: true,
  },
  {
    path: SITE_PATHS.BARGE_CLERK_DETAIL,
    component: bargeClerkManagement(BargeClerkDetailPage),
    exact: true,
  },
  {
    path: SITE_PATHS.NOT_FOUND,
    component: NotFoundPage,
    exact: true,
  },
  {
    path: SITE_PATHS.UNAUTHORIZED,
    component: UnauthorizedPage,
    exact: true,
  },
];

function Routes() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallBackComponent}>
      <NotificationManager />
      <ScrollToTop>
        <RouterRoutes>
          {routes.map(({ exact, path, component: Component }) => {
            const ComponentWithRouter = withRouter(Component);
            return <Route exact={exact} key={path} path={path} element={<ComponentWithRouter />} />;
          })}
          <Route path="*" element={<Navigate to={SITE_PATHS.NOT_FOUND} replace />} />
        </RouterRoutes>
      </ScrollToTop>
    </ErrorBoundary>
  );
}

export default withRouter(Routes);
