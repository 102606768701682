/* eslint-disable react/jsx-no-useless-fragment */
// @flow
import { Button, Col, Row } from 'antd';
import qs from 'qs';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  FilterOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { SITE_PATHS } from '../../../config/api.constants';
import { FILTER_TYPES } from '../../../constants/filters.constants';
import { EVENT_TYPES } from '../../../constants/update.constants';
import {
  getBargeFilterAction,
  getBargeOperatorFilterAction,
  getBtsIdFilterAction,
  getQuayPlannedFilterAction,
  getQuayRequestedFilterAction,
  getShiftsFilterAction,
  getStatusVisitPageAction,
  getVisitPageAction,
  priorityVisitAction,
  processVisitAction,
} from '../../../store/actions';
import { refreshAction } from '../../../store/actions/socket.actions';
import { updateVisitList } from '../../../store/handlers/visits.handler';
import type {
  TFilterObject,
  TPageable,
  TPageWithFilter,
  TPaging,
  TSortingRequest,
  TVisit,
  TVisitDataProcessorOverview,
  TVisitFilter,
} from '../../../types';
import { createPath } from '../../../utils/routing.utils';
import { ActionLog } from '../../atoms/ActionLog';
import { Elearning } from '../../atoms/elearning';
import { PageLoader } from '../../atoms/PageLoader';
import {
  bargeNameAbbr,
  checkOrError,
  lplToRequest,
  overlandedContainers,
  renderBTSTotal,
  renderOPTOrRTAOrETA,
  renderPriority,
  renderProcessed,
  renderQuay,
  renderStatus,
  talMismatch,
} from '../../atoms/renderers';
import { Socket } from '../../atoms/Socket';
import { Filter } from '../../atoms/Tables/filters/Filter';
import { FilterBoolean } from '../../atoms/Tables/filters/FilterBoolean';
import { FilterRange } from '../../atoms/Tables/filters/FilterRange';
import { FilterWithSearch } from '../../atoms/Tables/filters/FilterWithSearch';
import { FilterWithSearchAndObjects } from '../../atoms/Tables/filters/FilterWithSearchAndObjects';
import { Table } from '../../atoms/Tables/Table';
import { ContactInfoModal } from '../../molecules/modals/ContactInfoModal';
import { ContextMenu } from '../../molecules/visits/ContextMenu';
import { SESSION_FILTER_KEY } from '../../../constants/visit.constants';
import { formatAscDesc, pageToPageRequest } from '../../../utils/format.utils';
import { getHash } from '../../../utils/general.utils';
import { useNavigate } from 'react-router-dom';

type TProps = {
  getBargeFilterAction: typeof getBargeFilterAction,
  getBargeOperatorFilterAction: typeof getBargeOperatorFilterAction,
  getBtsIdFilterAction: typeof getBtsIdFilterAction,
  getQuayPlannedFilterAction: typeof getQuayPlannedFilterAction,
  getQuayRequestedFilterAction: typeof getQuayRequestedFilterAction,
  getShiftsFilterAction: typeof getShiftsFilterAction,
  getStatusVisitPageAction: typeof getStatusVisitPageAction,
  getVisitPageAction: typeof getVisitPageAction,
  page: TPageWithFilter<TVisit, TVisitFilter>,
  priorityVisitAction: typeof priorityVisitAction,
  processVisitAction: typeof processVisitAction,
  refreshAction: typeof refreshAction,
};

function VisitListTableComponent(props: TProps) {
  const navigate = useNavigate();
  const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);
  const [visit, setVisit] = useState<TVisit | null>(null);
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false);
  const [isShiftFilterOpen, setIsShiftFilterOpen] = useState(false);
  const [isQuayPlannedOpen, setIsQuayPlannedOpen] = useState(false);
  const [isQuayRequestedOpen, setIsQuayRequestedOpen] = useState(false);
  const [isBtsIdOpen, setIsBtsIdOpen] = useState(false);
  const [isBargeOpen, setIsBargeOpen] = useState(false);
  const [isBargeOperatorOpen, setIsBargeOperatorOpen] = useState(false);

  const updateUrl = (urlObject?: { filter?: TVisitFilter | {}, paging: TPaging, sorting?: ?TSortingRequest }) => {
    const filter = qs.stringify(urlObject);
    sessionStorage.setItem(SESSION_FILTER_KEY, filter);
    navigate(`/visits?${filter}`);
  };

  const handleSearch = (selectedValues: string[] | TFilterObject[], filterProp: string) => {
    if (!props.page || !props.page?.data) {
      return null;
    }

    const { filter, data } = props.page;
    const { pageable, sort } = data;

    if (!filter) {
      throw Error('Filter values should be provided.');
    }

    const newFilter: TVisitFilter = { ...filter };
    newFilter[filterProp] = selectedValues;
    const paging: TPageable = pageable;
    paging.pageNumber = 0;

    return updateUrl({ filter: newFilter, paging, sort });
  };

  const clearFilter = () => {
    updateUrl();
  };

  const update = (pagination: any, filters: any, sorter: any) => {
    if (!props.page || !props.page?.data) {
      return null;
    }

    const { filter, data } = props.page;
    const { pageable } = data;
    return updateUrl({
      filter,
      paging: { number: pagination.current - 1, size: pageable.pageSize },
      sorting: { by: [sorter.columnKey], order: sorter.order },
    });
  };

  const getSortOrder = (key: string) => {
    if (!props.page || !props.page?.data) {
      return null;
    }

    const { sort } = props.page.data;
    if (sort && sort.by && sort.by.indexOf(key) > -1) {
      return formatAscDesc(sort.order);
    }
    return null;
  };

  const columns: ColumnsType<TVisit> = [
    {
      align: 'center',
      dataIndex: 'processed',
      filterDropdown: ({ confirm }) => (
        <FilterBoolean
          confirm={confirm}
          trueName={<FormattedMessage id="organisms.visits.visit_lists.processed.filter.true"
                                      defaultMessage="Processed" />}
          falseName={<FormattedMessage id="organisms.visits.visit_lists.processed.filter.false"
                                       defaultMessage="Non-processed" />}
          filterType={FILTER_TYPES.VISITS}
          filterProp="processed"
          search={handleSearch}
        />
      ),
      filterIcon: () => {
        if (props.page.filter && props.page.filter.processed && props.page.filter.processed.length) {
          return <FilterFilled
            className={props.page.filter && props.page.filter.processed && props.page.filter.processed.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined
          className={props.page.filter && props.page.filter.processed && props.page.filter.processed.length ? 'filtered' : ''} />;
      },
      key: 'processed',
      render: renderProcessed,
      sorter: true,
      sortOrder: getSortOrder('processed'),
      title: (
        <>
          <Elearning contentKey="DP_OVERVIEW_PROCESSED_COLUMN" parentTagName="th" />
          <FormattedMessage id="organisms.visits.visit_list.processed_title" defaultMessage="Processed" />
        </>
      ),
      width: 94,
    },
    {
      align: 'center',
      dataIndex: 'priority',
      filterDropdown: ({ confirm }) => <FilterBoolean confirm={confirm} falseName="Non-prio" trueName="Prio"
                                                      filterType={FILTER_TYPES.VISITS} filterProp="priority"
                                                      search={handleSearch} />,
      filterIcon: () => {
        if (props.page.filter && props.page.filter.priority.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.priority.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.priority.length ? 'filtered' : ''} />;
      },
      key: 'priority',
      render: renderPriority,
      title: <FormattedMessage id="organisms.visits.visit_list.priority" defaultMessage="Prio." />,
      sorter: true,
      sortOrder: getSortOrder('priority'),
      width: 100,
    },
    {
      align: 'center',
      key: 'shift',
      dataIndex: 'shift',
      sorter: true,
      sortOrder: getSortOrder('shift'),
      title: <FormattedMessage id="organisms.visits.visit_list.shift_title" defaultMessage="Shift" />,
      filterDropdown: ({ confirm }) => (
        <Filter filterType={FILTER_TYPES.VISITS} filterProp="shift" search={handleSearch} confirm={confirm}
                visible={isShiftFilterOpen} dataAction={props.getShiftsFilterAction} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsShiftFilterOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.shift.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.shift.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.shift.length ? 'filtered' : ''} />;
      },
      width: 90,
    },
    {
      align: 'left',
      key: 'quayRequestedName',
      render: (visit: TVisitDataProcessorOverview) => renderQuay({ name: visit.quayRequestedName }),
      sorter: true,
      sortOrder: getSortOrder('quayRequestedName'),
      title: <FormattedMessage id="organisms.visits.visit_list.quay_requested_title" defaultMessage="Quay requested" />,
      filterDropdown: ({ confirm }) => (
        <FilterWithSearchAndObjects
          filterType={FILTER_TYPES.VISITS}
          filterProp="quayRequested"
          search={handleSearch}
          confirm={confirm}
          visible={isQuayRequestedOpen}
          dataAction={props.getQuayRequestedFilterAction}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsQuayRequestedOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.quayRequested.length) {
          return <FilterFilled
            className={props.page.filter && props.page.filter.quayRequested.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined
          className={props.page.filter && props.page.filter.quayRequested.length ? 'filtered' : ''} />;
      },
      width: 120,
    },
    {
      align: 'left',
      key: 'quayPlannedName',
      render: (visit: TVisitDataProcessorOverview) => renderQuay({ name: visit.quayPlannedName }),
      sorter: true,
      sortOrder: getSortOrder('quayPlannedName'),
      title: <FormattedMessage id="organisms.visits.visit_list.quay_planned_title" defaultMessage="Quay planned" />,
      filterDropdown: ({ confirm }) => (
        <FilterWithSearchAndObjects
          filterType={FILTER_TYPES.VISITS}
          filterProp="quayPlanned"
          search={handleSearch}
          confirm={confirm}
          visible={isQuayPlannedOpen}
          dataAction={props.getQuayPlannedFilterAction}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsQuayPlannedOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.quayPlanned.length) {
          return <FilterFilled
            className={props.page.filter && props.page.filter.quayPlanned.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined
          className={props.page.filter && props.page.filter.quayPlanned.length ? 'filtered' : ''} />;
      },
      width: 120,
    },
    {
      align: 'left',
      dataIndex: 'btsId',
      key: 'btsId',
      sorter: true,
      sortOrder: getSortOrder('btsId'),
      title: <FormattedMessage id="organisms.visits.visit_list.bts_id_title" defaultMessage="BTS ID" />,
      filterDropdown: ({ confirm }) => (
        <FilterWithSearch filterType={FILTER_TYPES.VISITS} filterProp="btsId" search={handleSearch} confirm={confirm}
                          dataAction={props.getBtsIdFilterAction} visible={isBtsIdOpen} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsBtsIdOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.btsId.length) {
          return <FilterFilled
            className={props.page.filter && props.page.filter.btsId && props.page.filter.btsId.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined
          className={props.page.filter && props.page.filter.btsId && props.page.filter.btsId.length ? 'filtered' : ''} />;
      },
    },
    {
      align: 'left',
      className: 'relative',
      dataIndex: 'bargeName',
      key: 'bargeName',
      sorter: true,
      sortOrder: getSortOrder('bargeName'),
      title: <FormattedMessage key="test" id="organisms.visits.visit_list.barge_title" defaultMessage="Barge" />,
      filterDropdown: ({ confirm }) => (
        <FilterWithSearchAndObjects filterType={FILTER_TYPES.VISITS} filterProp="barge" search={handleSearch}
                                    confirm={confirm} visible={isBargeOpen} dataAction={props.getBargeFilterAction} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsBargeOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.barge.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.barge.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.barge.length ? 'filtered' : ''} />;
      },
      render: (name, visit: TVisitDataProcessorOverview) => bargeNameAbbr({
        name: visit.bargeName,
        tosName: visit.bargeTosName,
      }),
    },
    {
      align: 'left',
      dataIndex: 'bargeOperatorName',
      key: 'bargeOperatorName',
      sorter: true,
      sortOrder: getSortOrder('bargeOperatorName'),
      title: <FormattedMessage id="organisms.visits.visit_list.barge_operator_title" defaultMessage="Operator" />,
      filterDropdown: ({ confirm }) => (
        <FilterWithSearchAndObjects
          filterType={FILTER_TYPES.VISITS}
          filterProp="bargeOperator"
          search={handleSearch}
          confirm={confirm}
          visible={isBargeOperatorOpen}
          dataAction={props.getBargeOperatorFilterAction}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsBargeOperatorOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.bargeOperator.length) {
          return <FilterFilled
            className={props.page.filter && props.page.filter.bargeOperator.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined
          className={props.page.filter && props.page.filter.bargeOperator.length ? 'filtered' : ''} />;
      },
      width: 145,
    },
    {
      key: 'TAL',
      title: (
        <>
          <Elearning contentKey="DP_OVERVIEW_TAL_COLUMNS_HEADER" parentTagName="th" />
          <FormattedMessage id="organisms.visits.visit_list.tal_title" defaultMessage="TAL" />
        </>
      ),
      children: [
        {
          align: 'center',
          key: 'btsNumberOfContainers',
          dataIndex: 'btsNumberOfContainers',
          render: renderBTSTotal,
          sorter: true,
          sortOrder: getSortOrder('btsNumberOfContainers'),
          title: (
            <span>
              <ArrowDownOutlined className="text-green" />
              <ArrowUpOutlined type="arrow-up" className="text-red" />
            </span>
          ),
        },
        {
          align: 'center',
          key: 'btsNumberOfContainersIn',
          dataIndex: 'btsNumberOfContainersIn',
          sorter: true,
          sortOrder: getSortOrder('btsNumberOfContainersIn'),
          title: (
            <span>
              <ArrowDownOutlined className="text-green" />
            </span>
          ),
        },
        {
          align: 'center',
          key: 'btsNumberOfContainersOut',
          dataIndex: 'btsNumberOfContainersOut',
          sorter: true,
          sortOrder: getSortOrder('btsNumberOfContainersOut'),
          title: (
            <span>
              <ArrowUpOutlined className="text-red" />
            </span>
          ),
        },
        {
          align: 'center',
          key: 'talMismatch',
          render: talMismatch,
          sorter: true,
          sortOrder: getSortOrder('talMismatch'),
          title: (
            <span>
              <ExclamationCircleOutlined className="text-red" />
            </span>
          ),
          width: 40,
        },
        {
          align: 'center',
          dataIndex: 'lplToRequest',
          key: 'lplToRequest',
          render: lplToRequest,
          sorter: true,
          sortOrder: getSortOrder('lplToRequest'),
          title: <FormattedMessage id="organisms.visits.visit_list.lpl_title" defaultMessage="LPL" />,
          width: 80,
        },
        {
          align: 'center',
          dataIndex: 'overlandedContainers',
          key: 'overlandedContainers',
          render: overlandedContainers,
          sorter: true,
          sortOrder: getSortOrder('overlandedContainers'),
          title: <FormattedMessage id="organisms.visits.visit_list.overlanded_titleeee" defaultMessage="O" />,
          // width: 70,
          filterDropdown: ({ confirm }) => (
            <FilterBoolean
              confirm={confirm}
              filterType={FILTER_TYPES.VISITS}
              filterProp="overlanded"
              trueName={<FormattedMessage id="organisms.visits.visit_list.overlanded.filter.true"
                                          defaultMessage="Overlanded" />}
              falseName={<FormattedMessage id="organisms.visits.visit_list.error.overlanded.false"
                                           defaultMessage="No overlanded" />}
              search={handleSearch}
            />
          ),
          filterIcon: () => {
            if (props.page.filter && props.page.filter.overlanded && props.page.filter.overlanded.length) {
              return <FilterFilled
                className={props.page.filter && props.page.filter.overlanded && props.page.filter.overlanded.length ? 'filtered' : ''} />;
            }
            return <FilterOutlined
              className={props.page.filter && props.page.filter.overlanded && props.page.filter.overlanded.length ? 'filtered' : ''} />;
          },
        },
      ],
    },
    {
      children: [
        {
          align: 'center',
          dataIndex: 'internalErrors',
          key: 'internalErrors',
          render: checkOrError,
          sorter: true,
          sortOrder: getSortOrder('internalErrors'),
          title: <FormattedMessage id="organisms.visits.visit_list.int_title" defaultMessage="Int." />,
        },
        {
          align: 'center',
          dataIndex: 'externalErrors',
          key: 'externalErrors',
          render: checkOrError,
          sorter: true,
          sortOrder: getSortOrder('externalErrors'),
          title: <FormattedMessage id="organisms.visits.visit_list.ext_title" defaultMessage="Ext." />,
        },
      ],
      title: (
        <>
          <Elearning contentKey="DP_OVERVIEW_ERROR_COLUMNS_HEADER" parentTagName="th" />
          <FormattedMessage id="organisms.visits.visit_list.errors_title" defaultMessage="Errors" />
        </>
      ),
      filterDropdown: ({ confirm }) => (
        <FilterBoolean
          confirm={confirm}
          filterType={FILTER_TYPES.VISITS}
          filterProp="totalErrors"
          trueName={<FormattedMessage id="organisms.visits.visit_list.error.filter.true" defaultMessage="Errors" />}
          falseName={<FormattedMessage id="organisms.visits.visit_list.error.filter.false"
                                       defaultMessage="No errors" />}
          search={handleSearch}
        />
      ),
      filterIcon: () => {
        if (props.page.filter && props.page.filter.totalErrors.length) {
          return <FilterFilled
            className={props.page.filter && props.page.filter.totalErrors.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined
          className={props.page.filter && props.page.filter.totalErrors.length ? 'filtered' : ''} />;
      },
    },
    {
      align: 'left',
      key: 'etaRtaOps',
      dataIndex: 'status',
      render: renderOPTOrRTAOrETA,
      sorter: true,
      sortOrder: getSortOrder('etaRtaOps'),
      title: (
        <>
          <Elearning contentKey="DP_OVERVIEW_OPT_RTA_ETA_COLUMN" parentTagName="th" />
          <FormattedMessage id="organisms.visits.visit_list.rta_eta_title" defaultMessage="OPT / RTA / ETA" />
        </>
      ),
      filterDropdown: ({ confirm }) => <FilterRange filterType={FILTER_TYPES.VISITS} filterProp="etaRtaOps"
                                                    search={handleSearch} confirm={confirm} />,
      filterIcon: () => {
        if (props.page.filter && props.page.filter.etaRtaOps) {
          return <FilterFilled className={props.page.filter && props.page.filter.etaRtaOps.from ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.etaRtaOps.from ? 'filtered' : ''} />;
      },
      width: 190,
      defaultSortOrder: 'ascend',
    },
    {
      align: 'left',
      dataIndex: 'status',
      key: 'status',
      render: renderStatus,
      sorter: false,
      sortOrder: getSortOrder('status'),
      title: (
        <>
          <Elearning contentKey="DP_OVERVIEW_STATUS_COLUMN" parentTagName="th" />
          <FormattedMessage id="organisms.visits.visit_list.status_bts_title" defaultMessage="Status" />
        </>
      ),
      filterDropdown: ({ confirm }) => (
        <Filter
          filterType={FILTER_TYPES.VISITS}
          filterProp="status"
          search={handleSearch}
          parseEnum={renderStatus}
          confirm={confirm}
          visible={isStatusFilterOpen}
          dataAction={props.getStatusVisitPageAction}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsStatusFilterOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.status.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.status.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.status.length ? 'filtered' : ''} />;
      },
      width: 110,
    },
    {
      align: 'left',
      key: 'actionLog',
      title: (
        <>
          <Elearning contentKey="DP_OVERVIEW_ACTION_LOG_COLUMN" parentTagName="th" />
          <FormattedMessage id="organisms.visits.visit_list.action_log_title" defaultMessage="Action log" />
        </>
      ),
      render: (text: string, record: TVisit) => <ActionLog actions={record.actions} />,
      className: 'context-menu-cell',
    },
    {
      key: 'contextMenu',
      render: (text: string, record: TVisit, index: number) => (
        <ContextMenu
          active={activeMenuIndex === index}
          priorityVisitAction={props.priorityVisitAction}
          processVisitAction={props.processVisitAction}
          setMenuActive={() => setActiveMenuIndex(index)}
          setMenuInactive={() => setActiveMenuIndex(-1)}
          showModal={() => {
            setModalVisible(true);
            setVisit(record);
          }}
          visit={record}
        />
      ),
      title: <Elearning contentKey="DP_OVERVIEW_CONTEXT_MENU_COLUMN" parentTagName="th" />,
      className: 'context-menu-cell',
    },
  ];

  return (
    <>
      {props.loadingPage && !props.page?.data ? (
        <PageLoader />
      ) : (
        <Socket
          topics={[EVENT_TYPES.DATA_PROCESSOR_ROW]}
          onMessage={(message) => {
            props.refreshAction(message, updateVisitList({
              getVisitPageAction: props.getVisitPageAction,
              pageRequest: pageToPageRequest(props.page),
            }));
          }}
          socketKey={`V_VisitListTable.${
            props.page.filter && `${props.page.filter.ownShift}.${props.page.filter.ownQuay}.${props.page.filter.otherShift}.${props.page.filter.otherQuay}.${getHash(props.page)}`
          }`}
        >
          <Row>
            <Col span={2} style={{ marginBottom: 10, marginTop: 10 }}>
              <Button onClick={clearFilter}>
                <FormattedMessage id="organisms.visits.visit_list.clearFilter" defaultMessage="Clear filters" />
              </Button>
            </Col>
          </Row>
          <Table
            className="visit-overview"
            page={props.page}
            columns={columns}
            pagination
            rowKey="id"
            size="small"
            onRow={(row) => ({
              onClick: () => !modalVisible && navigate(createPath(SITE_PATHS.VISIT_DETAIL, { id: row.id })),
            })}
            onChange={update}
          />
          <ContactInfoModal visit={visit} hideModal={() => setModalVisible(false)} showDialog={modalVisible} />
        </Socket>
      )}
    </>
  );
}

export const VisitListTable = VisitListTableComponent;
