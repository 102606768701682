// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';
import type { TElearningPopover } from '../../../types';
import { ElearningForm } from '../../organisms/elearning/ElearningForm';
import { elearningSaveContentAction } from '../../../store/actions';
import { useParams } from 'react-router-dom';

export type TElearningEditProps = {
  intl: any,
  match: any,
  // keys: string[];
};

const TRANSLATIONS = {
  TITLE: { id: 'templates.crud_sider.elearning_title', defaultMessage: 'Elearning' },
  OVERVIEW: { id: 'general.actions.edit', defaultMessage: 'Edit' },
};

function getDefaultContent(key: string) {
  return { key, title: '', body: '' };
}

function checkData(key: string, data: ?TElearningPopover) {
  if (!data || data.error) {
    return getDefaultContent(key);
  }
  return data;
}

function ElearningEdit(props: TElearningEditProps) {
  // Layout
  const title = `${props.intl.formatMessage(TRANSLATIONS.TITLE)} ${props.intl.formatMessage(TRANSLATIONS.OVERVIEW)}`;
  const { key } = useParams();

  // Connect the form
  const dispatch = useDispatch();

  // Get Form content
  const data = useSelector((state) => {
    if (state.elearning.content) {
      return checkData(key, state.elearning.content[key]);
    }
    return getDefaultContent(key);
  });

  const onSubmit = (newData: TElearningPopover) => {
    dispatch(elearningSaveContentAction(newData));
  };

  return (
    <EditTemplate title={title} menuBlock="elearning" menuItem="elearning-overview">
      <ElearningForm onSubmit={onSubmit} data={data} />
    </EditTemplate>
  );
}

export const ElearningEditPage = injectIntl(ElearningEdit);
