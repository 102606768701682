// @flow
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';
import { createPath } from '../../../utils/routing.utils';
import { DeleteButton } from '../../atoms/DeleteActions';

type TProps = {
  backRoute: string,
  deleteAction: Function,
  editRoute: string,
  entity: Object,
  id: any,
  title: string,
};

export function DetailHeader(props: TProps) {
  const navigate = useNavigate();
  return (
    <Row className="master-header">
      <Col span={14} className="flex-left flex-vertical-center">
        <h1>{props.title}</h1>
        <Link to={createPath(props.editRoute, { id: props.id })}>
          <FormattedMessage id="general.actions.edit" defaultMessage="Edit" />
        </Link>
      </Col>
      {props.entity && !props.entity.deletable ? null : (
        <Col span={2} className="flex-right flex-vertical-center">
          <DeleteButton
            onConfirm={() => {
              props.deleteAction(props.entity);
              navigate(props.backRoute);
            }}
          />
        </Col>
      )}
    </Row>
  );
}
