// @flow
import * as React from 'react';
import withRouter from './withRouter';

type TProps = {
  children: any,
  location: Location,
};

class ScrollToTop extends React.Component<TProps> {
  componentDidUpdate(prevProps: TProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
