// @flow
import React, { useEffect } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constants/pagination.constants';
import { getBargePageAction, removeBargeAction } from '../../../store/actions';
import { bargeListSelector } from '../../../store/selectors/barge.selectors';
import { createPath } from '../../../utils/routing.utils';
import { SimpleTable } from '../../atoms/Tables/SimpleTable';
import { ListTemplate } from '../../templates/MasterData/ListTemplate';
import { SITE_PATHS } from '../../../config/api.constants';
import { BargeTableProps } from '../../atoms/Tables/BargeTableProps';
import { useNavigate } from 'react-router-dom';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.barge.ha', defaultMessage: 'Barges' },
  button: { id: 'pages.barge.list.header.button', defaultMessage: 'Add new barge' },
});

function BargeList(props: TProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(bargeListSelector);

  const loadPage = (page: number, pageSize: number) => {
    dispatch(getBargePageAction({ filter: {}, paging: { size: pageSize, number: page } }));
  };

  const goToDetail = (record) => navigate(createPath(SITE_PATHS.BARGE_DETAIL, { id: record.id }));
  const deleteRow = (record) => dispatch(removeBargeAction(record));

  useEffect(() => {
    loadPage(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
  }, []);

  return (
    <ListTemplate
      buttonText={props.intl.formatMessage(EXTRA_TRANSLATIONS.button)}
      createRoute={SITE_PATHS.BARGE_CREATE}
      title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)}
      menuBlock="barges"
      menuItem="barges-overview"
    >
      <SimpleTable onRowClick={goToDetail} onPageChange={loadPage} columns={BargeTableProps(deleteRow)} data={data} rowKey="id" className="barge-table-list" />
    </ListTemplate>
  );
}

export const BargeListPage = injectIntl(BargeList);
