// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SITE_PATHS } from '../../../config/api.constants';
import { getBargeAction, removeBargeAction } from '../../../store/actions';
import type { TGlobalState, TBarge } from '../../../types';
import { GeneralDetailTable } from '../../atoms/Tables/MDDetailTables/GeneralDetailTableColumns';
import { DetailTemplate } from '../../templates/MasterData/DetailTemplate';
import { Params } from 'react-router-dom';

type TProps = {
  barge: TBarge,
  getBargeAction: typeof getBargeAction,
  intl: intlShape,
  loading: boolean,
  match: any,
  removeBargeAction: typeof removeBargeAction,
  params: Params<String>,
};

const EXTRA_TRANSLATIONS = defineMessages({
  eni: { id: 'barge.eni', defaultMessage: 'eni' },
  tos_name: { id: 'barge.tos_name', defaultMessage: 'tos name' },
  type: { id: 'barge.type', defaultMessage: 'barge type' },
  length: { id: 'barge.length', defaultMessage: 'length (cm)' },
  width: { id: 'barge.width', defaultMessage: 'width (cm)' },
  mmsi: { id: 'barge.mmsi', defaultMessage: 'MMSI' },
});

class BargeDetail extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.params;
    this.props.getBargeAction(id);
  }

  extractBargeDetails = (barge: TBarge) => {
    const details = [];
    if (!barge) return details;

    if (barge.eni)
      details.push({
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.eni),
        value: barge.eni,
      });

    if (barge.tosName)
      details.push({
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.tos_name),
        value: barge.tosName,
      });

    if (barge.type)
      details.push({
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.type),
        value: barge.type,
      });

    if (barge.mmsi)
      details.push({
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.mmsi),
        value: barge.mmsi,
      });

    if (barge.length)
      details.push({
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.length),
        value: barge.length,
      });

    if (barge.width)
      details.push({
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.width),
        value: barge.width,
      });

    return details;
  };

  render() {
    return (
      <DetailTemplate
        backRoute={SITE_PATHS.BARGE_LIST}
        deleteAction={this.props.removeBargeAction}
        editRoute={SITE_PATHS.BARGE_UPDATE}
        entity={this.props.barge}
        headerTitle={`${this.props.barge && this.props.barge.name}`}
        id={this.props.barge && this.props.barge.id}
        loading={this.props.loading}
        menuBlock="barges"
        menuItem="barges-overview"
      >
        <GeneralDetailTable details={this.extractBargeDetails(this.props.barge)} />
      </DetailTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  barge: state.barges.detail,
  loading: state.barges.loadingDetail,
});

export const BargeDetailPage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, { getBargeAction, removeBargeAction })(BargeDetail));
