// @flow
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import * as React from 'react';
import { useEffect } from 'react';
import type { Location } from 'react-router';
import { connect } from 'react-redux';
import {
  getBargeFilterAction,
  getBargeOperatorFilterAction,
  getBtsIdFilterAction,
  getQuayPlannedFilterAction,
  getQuayRequestedFilterAction,
  getShiftsFilterAction,
  getStatusVisitPageAction,
  getVisitPageAction,
  priorityVisitAction,
  processVisitAction,
} from '../../../store/actions';
import { refreshAction } from '../../../store/actions/socket.actions';
import type { TPageWithFilter, TVisit, TVisitFilter } from '../../../types';
import { VisitListTable } from '../../organisms/visits/VisitListTable';
import { DPTemplate } from '../../templates/DPTemplate';
import { SESSION_FILTER_KEY } from '../../../constants/visit.constants';
import { useNavigate } from 'react-router-dom';

type TProps = {
  getBargeFilterAction: typeof getBargeFilterAction,
  getBargeOperatorFilterAction: typeof getBargeOperatorFilterAction,
  getBtsIdFilterAction: typeof getBtsIdFilterAction,
  getQuayPlannedFilterAction: typeof getQuayPlannedFilterAction,
  getQuayRequestedFilterAction: typeof getQuayRequestedFilterAction,
  getShiftsFilterAction: typeof getShiftsFilterAction,
  getStatusVisitPageAction: typeof getStatusVisitPageAction,
  getVisitPageAction: typeof getVisitPageAction,
  loadingPage: boolean,
  location: Location,
  page: TPageWithFilter<TVisit, TVisitFilter>,
  priorityVisitAction: typeof priorityVisitAction,
  processVisitAction: typeof processVisitAction,
  refreshAction: typeof refreshAction,
};

function VisitList(props: TProps) {
  const navigate = useNavigate();
  const parseUrl = (props: TProps) => {
    const { location } = props;
    const parsedUrl = qs.parse(location.search, { ignoreQueryPrefix: true });
    const sessionFilter = sessionStorage.getItem(SESSION_FILTER_KEY);
    if (isEmpty(parsedUrl) && !!sessionFilter && sessionFilter !== '') {
      navigate(`/visits?${sessionFilter}`);
    }
    if (!isEmpty(parsedUrl)) {
      const newFilter = parsedUrl.filter || {};
      const newSorter = parsedUrl.sorting || null;
      const newPaging = parsedUrl.paging;

      const pageRequest = {
        filter: newFilter,
        paging: newPaging,
        sorting: newSorter,
        prio: true,
      };
      props.getVisitPageAction(pageRequest);
    } else {
      props.getVisitPageAction();
    }
  };

  useEffect(() => {
    parseUrl(props);
  }, []);

  useEffect(() => {
    parseUrl(props);
  }, [props.location.search]);

  return (
    <DPTemplate menuBlock="visits" menuItem="visits">
      <VisitListTable
        page={props.page}
        processVisitAction={props.processVisitAction}
        loadingPage={props.loadingPage}
        getVisitPageAction={props.getVisitPageAction}
        priorityVisitAction={props.priorityVisitAction}
        getStatusVisitPageAction={props.getStatusVisitPageAction}
        getShiftsFilterAction={props.getShiftsFilterAction}
        getQuayRequestedFilterAction={props.getQuayRequestedFilterAction}
        getQuayPlannedFilterAction={props.getQuayPlannedFilterAction}
        getBtsIdFilterAction={props.getBtsIdFilterAction}
        getBargeFilterAction={props.getBargeFilterAction}
        getBargeOperatorFilterAction={props.getBargeOperatorFilterAction}
        refreshAction={props.refreshAction}
      />
    </DPTemplate>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  loadingPage: state.visits.loadingPage,
  page: state.visits.page,
});

export const VisitListPage = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getVisitPageAction,
  priorityVisitAction,
  processVisitAction,
  getStatusVisitPageAction,
  getShiftsFilterAction,
  getQuayRequestedFilterAction,
  getQuayPlannedFilterAction,
  getBtsIdFilterAction,
  getBargeFilterAction,
  getBargeOperatorFilterAction,
  refreshAction,
})(VisitList);
