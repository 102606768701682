// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { SITE_PATHS } from '../../../config/api.constants';
import { getDetailsAction } from '../../../store/actions/lpl.actions';
import { createLPLRemarkAction } from '../../../store/actions/lpl_remark.actions';
import type { TGlobalState, TPageWithFilter, TVisit, TVisitFilter } from '../../../types';
import type { TVisitRemark } from '../../../types/remark.types';
import { LPLDetail } from '../../organisms/lpl_detail/LPLDetail';
import { DPTemplate } from '../../templates/DPTemplate';
import { Params } from 'react-router-dom';

type TProps = {
  createLPLRemarkAction: typeof createLPLRemarkAction,
  getDetailsAction: typeof getDetailsAction,
  loadingRemarks: boolean,
  match: any,
  page: TPageWithFilter<TVisit, TVisitFilter>,
  remarks: TVisitRemark[],
  visit: TVisit,
  params: Params<String>;
};

class LplDetailComponent extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.params;
    this.props.getDetailsAction(id);
  }

  render() {
    if (!this.props.visit) return null;

    return (
      <DPTemplate menuBlock={SITE_PATHS.LPL_LIST} menuItem={SITE_PATHS.LPL_LIST}>
        <LPLDetail
          getDetailsAction={this.props.getDetailsAction}
          visit={this.props.visit}
          remarks={this.props.remarks}
          loadingRemarks={this.props.loadingRemarks}
          createLPLRemarkAction={this.props.createLPLRemarkAction}
          page={this.props.page}
        />
      </DPTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  remarks: state.lplRemarks.list,
  loadingRemarks: state.lplRemarks.loadingList,
  page: state.lpl.page,
  visit: state.visits.detail,
});

export const LPLDetailPage = connect<any, Object, _, _, _, _>(mapStateToProps, { createLPLRemarkAction, getDetailsAction })(LplDetailComponent);
